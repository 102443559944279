<template>
<div>
          <div class="d-flex justify-content-between  gap-4 ">
<div><strong>{{title}}</strong></div>
<div class="text-truncate">
   <slot></slot>
   {{msg}}
</div>

          </div>
          <hr class="m-3">
</div>

</template>

<script>
export default {
    props: ['title', 'msg']
}
</script>