

<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
    <loading-vue v-if="load"></loading-vue>
    
        <div class="row g-2" v-else>
            <div class="col-md-5 mx-auto">

    
                
             
     <div class="row">
      <div class="col-12">
        <div class="card">

          <!-- end card header -->
          <div class="card-body">
            
            <div class="  mx-auto">
 <b-img class="img-thumbnail rounded-circle avatar-xl mx-auto d-block" alt="150x200" :src="link + items.image" data-holder-rendered="true"></b-img>             
         
         <h4 class="text-center mt-2">{{items.title}}</h4>
               
            </div>
<div class="d-grid gap-2 block" >
<b-button variant="primary" @click="processtrans" :disabled="submit" v-if="items.status == 1"> <div class="spinner-border spinner-border-sm" role="status" v-if="submit" > 
  <span class="sr-only">Loading...</span>
</div>  Pay ₦{{items.amount_paid}} </b-button>

</div>
          </div>
          
        </div>
      </div>
      
      <div class="col-12">
        <div class="card">
          
          <!-- end card header -->
          <div class="card-body">
            
          <AirtimeBox v-if="items.type == 1" :transaction="items" />
            <DataBox v-if="items.type == 2" :transaction="items" />
              <CabletvBox v-if="items.type == 3" :transaction="items" />
               <ElectricityBox v-if="items.type == 4" :transaction="items" />
                <ShowmaxBox v-if="items.type == 5" :transaction="items" />
                 <BettingBox v-if="items.type == 6" :transaction="items" />
                   <EducationBox v-if="items.type == 7" :transaction="items" />
            <div class="d-grid gap-2 block" >
            <b-button variant="primary" @click="processtrans" :disabled="submit" v-if="items.status == 1">
             <div class="spinner-border spinner-border-sm" role="status" v-if="submit" > 
  <span class="sr-only">Loading...</span>
</div>
            
          Pay ₦{{items.amount_paid}} </b-button>

</div>
          </div>
        </div>
      </div>
    </div>
      
             
            </div>
            <!-- end col -->
        </div>
        <!--end row-->
    
    
    </Layout>
   
</template>






<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import LoadingVue from "./components/Loading.vue";

import AirtimeBox from './components/transactions/AirtimeBox.vue'
import ElectricityBox from './components/transactions/ElectricityBox.vue'
import DataBox from './components/transactions/DataBox.vue'
import CabletvBox from './components/transactions/CabletvBox.vue'
import ShowmaxBox from './components/transactions/ShowmaxBox.vue'
import BettingBox from './components/transactions/BettingBox.vue'
import EducationBox from "./components/transactions/EducationBox.vue";
export default {
    page: {
        title: "Transaction",
        meta: [{ name: "description", content: appConfig.description }],
    },
    props: ['hash'],
    data() {
        return {
            link: process.env.VUE_APP_URL,
            title: "Transaction",
             submit: false,
            load: false,
            items: null,
        };
    },
    name: "Widgets",
    components: {
        Layout,
        PageHeader,
        LoadingVue,
        CabletvBox,
        ShowmaxBox,
        BettingBox,
        ElectricityBox,
        EducationBox,
        DataBox,

      AirtimeBox,
    },
    created() {
   this.gettrans();
    },

    methods: {
        async gettrans(){
    
               this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/get/transaction/"+ this.hash).catch((error) => {

this.load = false;
                Swal.fire("Error!", "Please Check Your Internet Connection", "error");
                throw error;

            }).then((response) => {
this.load = false;
                if (response.data.status == true) {

                    this.items = response.data.data.data;


                }

            })
        },




           async processtrans(){
    
               this.submit = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/process/payment/"+ this.hash).catch((error) => {

this.submit = false;
                Swal.fire("Error!", error.response.data.data, "error");
                throw error;

            }).then((response) => {
this.submit = false;
                if (response.data.status == true) {

                    this.items = response.data.data.data;
                     Swal.fire("Success!", response.data.data.message, "success");


                }

            })
        },
        
    },

};
</script>
<style scoped>
.active {
    border: 2px solid green;
}
</style>