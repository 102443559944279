<template >
    <div>
         <TransactionItem title="Status">



<b-badge v-if="transaction.status == 1" variant="warning" class="badge-label" ><i class="mdi mdi-circle-medium"></i> pending</b-badge>


<b-badge v-else-if="transaction.status == 2" variant="primary" class="badge-label" ><i class="mdi mdi-circle-medium"></i> processing</b-badge>



<b-badge v-else-if="transaction.status == 3" variant="success" class="badge-label" ><i class="mdi mdi-circle-medium"></i> completed</b-badge>

<b-badge v-else-if="transaction.status == 0" variant="danger" class="badge-label" ><i class="mdi mdi-circle-medium"></i> cancelled</b-badge>



         </TransactionItem>
          
             <TransactionItem title="Hash" :msg="'#'+transaction.code" />
               <TransactionItem title="Description" :msg="transaction.description" />
                 <TransactionItem title="Recipient" :msg="transaction.recipient" />
                  <TransactionItem title="Date"  >{{  dateTime(transaction.created_at)}}</TransactionItem>
                     <TransactionItem title="Product Amount" :msg="'₦ '+transaction.amount" />
                       <TransactionItem title="Voucher" v-if="transaction.token1 != null" :msg="transaction.token1" />
    </div>
</template>





<script>
import TransactionItem from '../TransactionItem.vue'
import moment from "moment";
export default {
    components: {
        TransactionItem,
    },
    props: ['transaction'],
     methods: {
        dateTime(value) {
      return moment(value).fromNow();
    },
}
}
</script>